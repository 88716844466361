<template>
    <div>
        <pdf v-for="i in numPages"
            :key="i"
            :src="src"
            :page="i"
            style="width: 100%"></pdf>
        <div class = "loader" :class="{hide: loaded}" :style="styleLoader"> </div>
    </div>
</template>

<script>
import pdf from 'vue-pdf'

var url = location.protocol + '//' + process.env.VUE_APP_API_PREFIX + location.hostname + process.env.VUE_APP_API_PORT + '/catalog.pdf';
var loadingTask = pdf.createLoadingTask(url);

export default {
    components: {
        pdf
    },
    data() {
        return {
            src: loadingTask ,
			numPages: undefined,
			loaded: false,
            styleLoader: {
                position: 'absolute',
                left: this.getWindowWidth(),
                top: this.getWindowHeight()
            }
        }
    },
    mounted() {
		this.loaded = false
        this.src.promise.then(pdf => {
            this.numPages = pdf.numPages;
			this.loaded = true
        });
    },
    methods: {
        getWindowWidth() {
            return ((window.innerWidth - 120) / 2).toString() + 'px'
        },
        getWindowHeight() {
            return ((window.innerHeight - 120) / 2).toString() + 'px'
        }
    }
}
</script>

<style>
    .navbar {
        display: none !important;
    }

    .loader {
        border: 5px solid #f3f3f3; /* Light grey */
        border-top: 5px solid #44BCFF; /* Blue */
        border-radius: 50%;
        width: 120px;
        height: 120px;
        animation: spin 2s linear infinite;
        /* position: absolute;
        top: 45%;
        left: 48%; */
    }

    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }

    .hide {
        display: none;
    }

</style>